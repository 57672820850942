<template>
    <div class="pdfdetail">
        <div class="pdf">
            <pdf ref="pdf" v-for="i in numPages" :key="i" :src="pdfUrl" :page="i">
            </pdf>
        </div>
    </div>
</template>
<script>
import pdf from "vue-pdf"
import CMapReaderFactory from 'vue-pdf/src/CMapReaderFactory.js'
import { isDev, requestDataTGPMethod, generateUUID, requestQueryDataTGPMethod } from "@/utils/appManager"
export default {
    name: "pdfdetail",
    components: { pdf },
    data() {
        return {
            source: '',
            pdfUrl: '',
            numPages: 1,
            idStr: '',
            articleDetail: {},
        };
    },
    methods: {
        pdfTask(pdfUrl) {
            var self = this
            this.pdfUrl = pdf.createLoadingTask({ url: pdfUrl, CMapReaderFactory })
            console.log('pdf', this.pdfUrl)
            this.pdfUrl.promise.then(pdf => {
                self.numPages = pdf.numPages
            }).catch((err) => {
                console.error('pdf加载失败');
            })
        },
        createResourseAction() {
            if (this.articleDetail.resources != undefined) {
                for (var i = 0; i < this.articleDetail.resources.length; i++) {
                    var oneResource = this.articleDetail.resources[i];
                    console.log(oneResource);
                    if (oneResource.type == 1) {
                    } else if (oneResource.type == 0) {
                    } else if (oneResource.type == 2) {
                        this.source = oneResource.url;
                        this.pdfTask(this.source)
                        break;
                    }
                }
            }
        },
    },
    mounted() {
        const { id } = this.$route.params;
        this.thoughId = id;
        requestQueryDataTGPMethod(
            `thoughts/${id}`,
            { include: "relates" },
            "getActiveDetail",
            "1",
            "get"
        );
        var viewport = document.querySelector("meta[name=viewport]");
        viewport.setAttribute('content', 'width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=4.0, user-scalable=yes');
    },
    created() {
        window["getActiveDetail"] = (data) => {
            window.document.title = "听股票动态";

            this.articleDetail = data;
            setTimeout(() => {
                this.createResourseAction();
            }, 300);
        };
        window["firstLoadData"] = (data) => {
          
            this.source = data.src;
            this.pdfTask(this.source)
        };
    }
};
</script>
<style scoped  lang="scss" >
.pdfdetail {}
</style>